import { Link, Typography } from "@mui/material";
import { useActor } from "@xstate/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import FullScreenLoader from "src/components/FullScreenLoader";
import HomePageText from "src/components/HomePageText";
import View from "src/components/View";
import { useApp } from "src/contexts/AppContext";
import withProtected from "src/hoc/withProtected";
import { AppTag } from "src/machines/appMachine";
import { NextPageWithLayout } from "./_app";

const Dashboard: NextPageWithLayout = () => {
  const router = useRouter();
  const { appService } = useApp();
  const [appState, send] = useActor(appService);

  const {
    auth,
    userFirstName,
    agencyUserDetails,
    artistHomePageDetails,
    cdHomePageDetails,
    cdCommentNotifications,
    isFirstDayOnAltai,
    userDetailsLoaded,
  } = appState.context;

  const [geeting, setGreeting] = useState("");

  const MINUTE_MS = 60000;

  useEffect(() => {
    getGreeting();
    const interval = setInterval(() => {
      getGreeting();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    send("UPDATE_HOME_PAGE_TEXT");
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const customToken = (router.query.customToken as string | null) ?? null;
    if (customToken !== null && customToken !== "") {
      send({ type: "INIT_LOGIN_WITH_CUSTOM_TOKEN", customToken: customToken });
    }
  }, [router.isReady, router.query]);

  const getGreeting = () => {
    var date = new Date();
    var time = date.getHours();

    if (time < 12) {
      setGreeting("Good morning");
    }
    if (time >= 12 && time < 18) {
      setGreeting("Good afternoon");
    }
    if (time >= 18) {
      setGreeting("Good evening");
    }
  };

  return (
    <>
      <Head>
        <title>Altai Casting | Dashboard</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <View>
        <View.Header variant="thin" />
        <View.Body>
          <FullScreenLoader loading={appState.hasTag(AppTag.loading)} />
          {userDetailsLoaded && (
            <>
              {!isFirstDayOnAltai && (
                <Typography variant="h1" sx={{ mb: 2, mt: "40px!important" }}>
                  <HomePageText>
                    {geeting}, {userFirstName}.
                  </HomePageText>
                </Typography>
              )}
              {isFirstDayOnAltai && (
                <>
                  <Typography variant="h1" sx={{ mb: 2, mt: "40px!important" }}>
                    <HomePageText>
                      Welcome to the Altai family, {userFirstName}.
                    </HomePageText>
                  </Typography>

                  {/* -------------------------------- */}
                  {/* Agent Text For First Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Agent" && (
                    <>
                      {agencyUserDetails &&
                        agencyUserDetails?.activeCastingBriefCount == 0 && (
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              Before you get going, we recommend getting your{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  router.push("/artist-roster");
                                }}
                              >
                                Artist Roster
                              </Link>{" "}
                              into shape ready for submitting to Casting Briefs.
                            </HomePageText>
                          </Typography>
                        )}
                      {agencyUserDetails &&
                        agencyUserDetails?.activeCastingBriefCount > 0 && (
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              There’s lots to explore, but before you get going,
                              we recommend checking out the{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  router.push("/casting-briefs");
                                }}
                              >
                                Casting Briefs
                              </Link>{" "}
                              you have received.
                            </HomePageText>
                          </Typography>
                        )}
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          For any questions you may have, please visit{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href={
                              process.env.NEXT_PUBLIC_ALTAI_AGENT_SUPPORT_URL
                            }
                            target="_blank"
                          >
                            Altai Support
                          </Link>
                          .
                        </HomePageText>
                      </Typography>
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* Artist Text For First Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Artist" && (
                    <>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          Before you get going, join us for a{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href={
                              process.env
                                .NEXT_PUBLIC_ALTAI_SUPPORT_VIDEO_EXPLAINER_URL
                            }
                            target="_blank"
                          >
                            quick tour
                          </Link>{" "}
                          of Altai to run you through the essentials.
                        </HomePageText>
                      </Typography>

                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          Once you’re ready, head over to your{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              router.push("/edit-profile");
                            }}
                          >
                            Profile
                          </Link>{" "}
                          page to get started.
                        </HomePageText>
                      </Typography>

                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          For any questions you may have, please visit{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href={process.env.NEXT_PUBLIC_ALTAI_SUPPORT_URL}
                            target="_blank"
                          >
                            Altai Support
                          </Link>
                          .
                        </HomePageText>
                      </Typography>
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* CD Text For First Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "CastingDirector" &&
                    (!auth.isTeamAdmin ||
                      (auth.isTeamAdmin &&
                        cdHomePageDetails &&
                        cdHomePageDetails?.projectCount === 0)) && (
                      <>
                        {cdHomePageDetails?.projectCount === 0 && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {/* TODO - put this text back in once agencies are live */}
                                {/* There’s lots to explore, but before you get
                                going, we recommend you and your team start
                                building your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={() => {
                                    router.push("/lists/agency");
                                  }}
                                >
                                  Agency Lists
                                </Link>
                                . */}
                                We are in the process of onboarding agents and
                                their artists onto Altai, ready for you to add
                                to your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/lists/agency");
                                  }}
                                >
                                  Agency Lists
                                </Link>
                              </HomePageText>
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                This will make for smooth sailing when briefing
                                out your first project.
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                        {cdHomePageDetails &&
                          cdHomePageDetails?.projectCount > 0 && (
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {/* TODO - put this text back in once agencies are live */}
                                {/* There’s lots to explore, but before you get
                                going, we recommend checking out your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={() => {
                                    router.push("/lists/agency");
                                  }}
                                >
                                  Agency Lists
                                </Link>{" "} */}
                                We are in the process of onboarding agents and
                                their artists onto Altai, ready for you to add
                                to your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/lists/agency");
                                  }}
                                >
                                  Agency Lists
                                </Link>{" "}
                                and the{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/projects");
                                  }}
                                >
                                  Projects
                                </Link>{" "}
                                your team’s currently casting.
                              </HomePageText>
                            </Typography>
                          )}

                        <Typography variant="h1" sx={{ mb: 2 }}>
                          <HomePageText>
                            For any questions you may have, please visit{" "}
                            <Link
                              color="secondary"
                              underline="none"
                              href={
                                process.env.NEXT_PUBLIC_ALTAI_CD_SUPPORT_URL
                              }
                              target="_blank"
                            >
                              Altai Support
                            </Link>
                            .
                          </HomePageText>
                        </Typography>
                      </>
                    )}

                  {auth.userType === "CastingDirector" &&
                    auth.isTeamAdmin &&
                    cdHomePageDetails &&
                    cdHomePageDetails?.projectCount > 0 && (
                      <>
                        {/* -------------------------------- */}
                        {/* CD Text First Day Visit for Admins - If the office has 1 active projects */}
                        {/* -------------------------------- */}

                        {cdHomePageDetails.activeProjectCount === 1 && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                Your current project is{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push({
                                      pathname: "/projects/[projectId]",
                                      query: {
                                        projectId:
                                          cdHomePageDetails.mostRecentProjectId,
                                      },
                                    });
                                  }}
                                >
                                  {cdHomePageDetails.mostRecentProjectName}
                                </Link>
                                .
                              </HomePageText>
                            </Typography>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                You have{" "}
                                {cdHomePageDetails.unreadNotificationCount}{" "}
                                unread{" "}
                                {cdHomePageDetails.unreadNotificationCount === 1
                                  ? "notification"
                                  : "notifications"}
                                .
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                        {/* -------------------------------- */}
                        {/* CD Text First Day Visit for Admins - If the office has more than 1 active projects */}
                        {/* -------------------------------- */}

                        {cdHomePageDetails.activeProjectCount > 1 && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                Your most recent projects are{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push({
                                      pathname: "/projects/[projectId]",
                                      query: {
                                        projectId:
                                          cdHomePageDetails.mostRecentProjectId,
                                      },
                                    });
                                  }}
                                >
                                  {cdHomePageDetails.mostRecentProjectName}
                                </Link>{" "}
                                and{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push({
                                      pathname: "/projects/[projectId]",
                                      query: {
                                        projectId:
                                          cdHomePageDetails.secondMostRecentProjectId,
                                      },
                                    });
                                  }}
                                >
                                  {
                                    cdHomePageDetails.secondMostRecentProjectName
                                  }
                                </Link>
                                .
                              </HomePageText>
                            </Typography>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                You have{" "}
                                {cdHomePageDetails.unreadNotificationCount}{" "}
                                unread{" "}
                                {cdHomePageDetails.unreadNotificationCount === 1
                                  ? "notification"
                                  : "notifications"}
                                .
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                        <Typography variant="h1" sx={{ mb: 2 }}>
                          <HomePageText>
                            {cdHomePageDetails.calendarItemCount === 1
                              ? "There is"
                              : "There are"}{" "}
                            {cdHomePageDetails.calendarItemCount} upcoming live{" "}
                            {cdHomePageDetails.calendarItemCount === 1
                              ? "session"
                              : "sessions"}{" "}
                            in your{" "}
                            <Link
                              color="secondary"
                              underline="none"
                              href="#"
                              onClick={(event) => {
                                event.preventDefault();
                                router.push("/calendar");
                              }}
                            >
                              Calendar
                            </Link>
                            .
                          </HomePageText>
                        </Typography>
                      </>
                    )}
                </>
              )}
              {!isFirstDayOnAltai && (
                <>
                  {/* -------------------------------- */}
                  {/* Agent Text Day to Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Agent" && agencyUserDetails && (
                    <>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          You have received{" "}
                          {agencyUserDetails.newCastingBriefCount} new{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              router.push("/casting-briefs");
                            }}
                          >
                            {agencyUserDetails.newCastingBriefCount === 1
                              ? "Casting Brief"
                              : "Casting Briefs"}
                          </Link>
                          .
                        </HomePageText>
                      </Typography>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          There{" "}
                          {agencyUserDetails.newStatusReportUpdateCount === 1
                            ? "is"
                            : "are"}{" "}
                          {agencyUserDetails.newStatusReportUpdateCount} new{" "}
                          {agencyUserDetails.newStatusReportUpdateCount === 1
                            ? "update"
                            : "updates"}{" "}
                          in your{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              router.push("/status-report");
                            }}
                          >
                            Status Report
                          </Link>
                          .
                        </HomePageText>
                      </Typography>
                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          {agencyUserDetails.calendarItemCount === 1
                            ? "There is"
                            : "There are"}{" "}
                          {agencyUserDetails.calendarItemCount}{" "}
                          {agencyUserDetails.calendarItemCount === 1
                            ? "item"
                            : "items"}{" "}
                          in your{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              router.push("/calendar");
                            }}
                          >
                            Calendar
                          </Link>{" "}
                          for today.
                        </HomePageText>
                      </Typography>
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* Artist Text Day to Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "Artist" && artistHomePageDetails && (
                    <>
                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Incomplete */}
                      {/* -------------------------------- */}
                      {!artistHomePageDetails.isProfileCompleted && (
                        <>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              Your profile is not yet complete and not yet
                              featured on the Artist Directory. We recommend
                              heading over to your{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  router.push("/edit-profile");
                                }}
                              >
                                Profile
                              </Link>{" "}
                              page to get started.
                            </HomePageText>
                          </Typography>

                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              You might also want to check out our{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href={
                                  process.env.NEXT_PUBLIC_ALTAI_ESSENTIALS_GUIDE
                                }
                                target="_blank"
                              >
                                essentials guide
                              </Link>{" "}
                              to designing a beautiful and engaging profile if
                              you’re looking to establish yourself within the
                              industry.
                            </HomePageText>
                          </Typography>

                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              For any questions you may have, please visit{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href={process.env.NEXT_PUBLIC_ALTAI_SUPPORT_URL}
                                target="_blank"
                              >
                                Altai Support
                              </Link>
                              .
                            </HomePageText>
                          </Typography>
                        </>
                      )}

                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Complete and, on lite tier and Represented / Freelance */}
                      {/* -------------------------------- */}
                      {artistHomePageDetails.isProfileCompleted &&
                        artistHomePageDetails.isLiteTierArtist && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                You are currently on the Lite Tier with a{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push(
                                      `/view-profile/${artistHomePageDetails.profileCode}`
                                    );
                                  }}
                                >
                                  basic profile
                                </Link>{" "}
                                that can be seen by Casting Directors. You can
                                check out the{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push(`/edit-profile`);
                                  }}
                                >
                                  Pro tier profile
                                </Link>{" "}
                                or see a{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push(
                                      `/account-details/subscription`
                                    );
                                  }}
                                >
                                  list of features
                                </Link>
                                .
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Complete and Represented */}
                      {/* -------------------------------- */}
                      {artistHomePageDetails.isProfileCompleted &&
                        artistHomePageDetails.isRepresented && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                You have{" "}
                                {
                                  artistHomePageDetails.newStatusReportUpdateCount
                                }{" "}
                                new{" "}
                                {artistHomePageDetails.newStatusReportUpdateCount ===
                                1
                                  ? "request and update"
                                  : "requests and updates"}{" "}
                                in your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/status-report");
                                  }}
                                >
                                  Status Report
                                </Link>
                                .
                              </HomePageText>
                            </Typography>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                {artistHomePageDetails.calendarItemCount === 1
                                  ? "There is"
                                  : "There are"}{" "}
                                {artistHomePageDetails.calendarItemCount}{" "}
                                upcoming{" "}
                                {artistHomePageDetails.calendarItemCount === 1
                                  ? "item"
                                  : "items"}{" "}
                                in your{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    router.push("/calendar");
                                  }}
                                >
                                  Calendar
                                </Link>
                                .
                              </HomePageText>
                            </Typography>
                          </>
                        )}

                      {/* -------------------------------- */}
                      {/* Artist Text Day to Day Visit - Profile Complete and Freelance */}
                      {/* -------------------------------- */}
                      {artistHomePageDetails.isProfileCompleted &&
                        !artistHomePageDetails.isRepresented && (
                          <>
                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                Is your profile fresh and up to date?
                              </HomePageText>
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                Check out our{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href={
                                    process.env
                                      .NEXT_PUBLIC_ALTAI_ESSENTIALS_GUIDE
                                  }
                                  target="_blank"
                                >
                                  essentials guide
                                </Link>{" "}
                                to designing a beautiful and engaging profile if
                                you’re looking to establish yourself within the
                                industry.
                              </HomePageText>
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2 }}>
                              <HomePageText>
                                For any questions you may have, please visit{" "}
                                <Link
                                  color="secondary"
                                  underline="none"
                                  href={
                                    process.env.NEXT_PUBLIC_ALTAI_SUPPORT_URL
                                  }
                                  target="_blank"
                                >
                                  Altai Support
                                </Link>
                                .
                              </HomePageText>
                            </Typography>
                          </>
                        )}
                    </>
                  )}

                  {/* -------------------------------- */}
                  {/* CD Text Day to Day Visit */}
                  {/* -------------------------------- */}

                  {auth.userType === "CastingDirector" && cdHomePageDetails && (
                    <>
                      {/* -------------------------------- */}
                      {/* CD Text Day to Day Visit - If the office has no active projects */}
                      {/* -------------------------------- */}

                      {cdHomePageDetails.activeProjectCount === 0 && (
                        <Typography variant="h1" sx={{ mb: 2 }}>
                          <HomePageText>
                            You have no active projects and{" "}
                            {cdHomePageDetails.unreadNotificationCount} unread
                            notifications.
                          </HomePageText>
                        </Typography>
                      )}

                      {/* -------------------------------- */}
                      {/* CD Text Day to Day Visit - If the office has 1 active projects */}
                      {/* -------------------------------- */}

                      {cdHomePageDetails.activeProjectCount === 1 && (
                        <>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              Your current project is{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  router.push({
                                    pathname: "/projects/[projectId]",
                                    query: {
                                      projectId:
                                        cdHomePageDetails.mostRecentProjectId,
                                    },
                                  });
                                }}
                              >
                                {cdHomePageDetails.mostRecentProjectName}
                              </Link>
                              .
                            </HomePageText>
                          </Typography>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              You have{" "}
                              {cdHomePageDetails.unreadNotificationCount} unread{" "}
                              {cdHomePageDetails.unreadNotificationCount === 1
                                ? "notification"
                                : "notifications"}
                              .
                            </HomePageText>
                          </Typography>
                        </>
                      )}

                      {/* -------------------------------- */}
                      {/* CD Text Day to Day Visit - If the office has more than 1 active projects */}
                      {/* -------------------------------- */}

                      {cdHomePageDetails.activeProjectCount > 1 && (
                        <>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              Your most recent projects are{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  router.push({
                                    pathname: "/projects/[projectId]",
                                    query: {
                                      projectId:
                                        cdHomePageDetails.mostRecentProjectId,
                                    },
                                  });
                                }}
                              >
                                {cdHomePageDetails.mostRecentProjectName}
                              </Link>{" "}
                              and{" "}
                              <Link
                                color="secondary"
                                underline="none"
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  router.push({
                                    pathname: "/projects/[projectId]",
                                    query: {
                                      projectId:
                                        cdHomePageDetails.secondMostRecentProjectId,
                                    },
                                  });
                                }}
                              >
                                {cdHomePageDetails.secondMostRecentProjectName}
                              </Link>
                              .
                            </HomePageText>
                          </Typography>
                          <Typography variant="h1" sx={{ mb: 2 }}>
                            <HomePageText>
                              You have{" "}
                              {cdHomePageDetails.unreadNotificationCount} unread{" "}
                              {cdHomePageDetails.unreadNotificationCount === 1
                                ? "notification"
                                : "notifications"}
                              .
                            </HomePageText>
                          </Typography>
                        </>
                      )}

                      <Typography variant="h1" sx={{ mb: 2 }}>
                        <HomePageText>
                          {cdHomePageDetails.calendarItemCount === 1
                            ? "There is"
                            : "There are"}{" "}
                          {cdHomePageDetails.calendarItemCount} upcoming live{" "}
                          {cdHomePageDetails.calendarItemCount === 1
                            ? "session"
                            : "sessions"}{" "}
                          in your{" "}
                          <Link
                            color="secondary"
                            underline="none"
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              router.push("/calendar");
                            }}
                          >
                            Calendar
                          </Link>
                          .
                        </HomePageText>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </View.Body>
      </View>
    </>
  );
};

export default withProtected({ redirect: "/login" })(Dashboard);
